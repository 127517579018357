<template>
  <div class="v-stack h-stretch gap-3" v-if="$store.state.accessLevel > 9">
    <div class="h-stack h-start pb-3 gap-3">
      <router-link :to="'/lifefinance/overview' + query">Overview</router-link>
      <router-link :to="'/lifefinance/income' + query">Income</router-link>
      <router-link :to="'/lifefinance/expenses' + query">Expenses</router-link>
      <router-link :to="'/lifefinance/gear'">Gear</router-link>
      <router-link :to="'/lifefinance/crewhours' + query"
        >Crew Hours</router-link
      >
      <router-link :to="'/lifefinance/crewpay'">Crew Pay</router-link>
      <router-link :to="'/lifefinance/loans'">Company Loans</router-link>
    </div>
    <div
      class="h-stack h-start gap-3"
      v-if="
        $route.path == '/lifefinance/overview' ||
        $route.path == '/lifefinance/income' ||
        $route.path == '/lifefinance/expenses' ||
        $route.path == '/lifefinance/crewhours'
      "
    >
      <label>Period</label>

      <select v-model="period" @change="onPeriodChange()">
        <option value="all">Eternity</option>
        <option value="year">Year</option>
        <option value="month">Month</option>
        <option value="range">Range</option>
      </select>
      <template v-if="period == 'year' || period == 'month'">
        <label>Year: </label>
        <select v-model="year" @change="onPeriodChange()">
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </template>
      <template v-if="period == 'month'">
        <label>Month: </label>
        <select v-model="month" @change="onPeriodChange()">
          <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
        </select>
      </template>
      <template v-if="period == 'range'">
        <label>Start: </label>
        <InputDatePicker v-model="startDate"></InputDatePicker>
        <label>End: </label>
        <InputDatePicker v-model="endDate"></InputDatePicker>
      </template>
    </div>
    <router-view
      v-if="$route.path == '/lifefinance/expenses'"
      :period="period"
      :year="year"
      :month="month"
      :startDate="startDate"
      :endDate="endDate"
      :gearFiltered="gearFiltered"
      :workdaysfilteredWithoutAdmin="workdaysfilteredWithoutAdmin"
      :oneTimePayFilteredWithoutAdmin="oneTimePayFilteredWithoutAdmin"
      :reccuringExpenses="reccuringExpenses"
      :oneTimeExpenses="oneTimeExpenses"
      :oneTimeExpenseCost="oneTimeExpenseCost"
      :totalGearCost="totalGearCost"
      :reccuringExpenseCost="reccuringExpenseCost"
      :crewCost="crewCost"
    ></router-view>
    <router-view
      v-if="$route.path == '/lifefinance/crewhours'"
      :workdays="workdaysfiltered"
      :oneTimePay="oneTimePayFiltered"
      v-on:refresh="refresh()"
      :users="users"
    ></router-view>
    <router-view
      v-if="$route.path == '/lifefinance/overview'"
      :oneTimeExpenseCost="oneTimeExpenseCost"
      :reccuringExpenseCost="reccuringExpenseCost"
      :totalGearCost="totalGearCost"
      :crewCost="crewCost"
      :expensesCost="expensesCost"
      :totalWorkForceCost="totalWorkForceCost"
      :totalCost="totalCost"
      :projectIncome="projectIncome"
      :totalIncome="projectIncome"
    ></router-view>
    <router-view
      v-if="$route.path == '/lifefinance/income'"
      :filteredProjects="filteredProjects"
      :projectIncome="projectIncome"
    ></router-view>
    <router-view v-if="$route.path == '/lifefinance/gear'"></router-view>
    <router-view
      v-if="
        $route.path != '/lifefinance/overview' &&
        $route.path != '/lifefinance/income' &&
        $route.path != '/lifefinance/expenses' &&
        $route.path != '/lifefinance/crewhours' &&
        $route.path != '/lifefinance/gear'
      "
    ></router-view>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import utils from "@/utils.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      period: "month",
      year: moment().year(),
      month: moment().month() + 1,
      startDate: moment().date(1).toISOString(),
      endDate: moment().date(moment().daysInMonth()).toISOString(),
      expenses: [],
      workdays: [],
      oneTimePay: [],
      gear: [],
      projects: [],
      query: "",
    };
  },
  components: {
    InputDatePicker,
  },
  watch: {
    startDate() {
      this.onPeriodChange();
    },
    endDate() {
      this.onPeriodChange();
    },
  },
  computed: {
    users() {
      let users = this.workdays
        .map((item) => item.user)
        .concat(this.oneTimePay.map((item) => item.user));
      if (users.length > 0) {
        return users.reduce((filter, current) => {
          var dk = filter.find((item) => item._id === current._id);
          if (!dk) {
            return filter.concat([current]);
          } else {
            return filter;
          }
        }, []);
      }
      return users;
    },
    years() {
      const oneTimePayYears = this.oneTimePay.reduce((a, b) => {
        return a.concat([moment(b.date).year()]);
      }, []);

      const workdaysYears = this.workdays.reduce((a, b) => {
        return a.concat([moment(b.day).year()]);
      }, []);

      const expensesYears = this.expenses.reduce((a, b) => {
        return a.concat([moment(b.date).year()]);
      }, []);

      const gearYears = this.gear.reduce((a, b) => {
        return a.concat([moment(b.dateOfPurchase).year()]);
      }, []);

      return Array.from(
        new Set(
          oneTimePayYears
            .concat(workdaysYears)
            .concat(expensesYears)
            .concat(gearYears)
        )
      ).sort();
    },
    totalCost() {
      return this.expensesCost + this.totalWorkForceCost;
    },
    totalWorkForceCost() {
      return this.crewCost + this.totalFreelancerCost;
    },
    totalFreelancerCost() {
      return 0;
    },
    expensesCost() {
      return (
        this.oneTimeExpenseCost + this.reccuringExpenseCost + this.totalGearCost
      );
    },
    totalGearCost() {
      let sum = 0;
      for (const gear of this.gearFiltered) {
        sum += gear.value;
      }

      for (const expense of this.oneTimeExpenses) {
        if (expense.category != "gear") {
          continue;
        }
        sum += expense.amount;
      }

      for (const expense of this.reccuringExpenses) {
        if (expense.category != "gear") {
          continue;
        }
        sum += expense.amount;
      }

      return sum;
    },
    oneTimeExpenseCost() {
      let sum = 0;
      for (const expense of this.oneTimeExpenses) {
        if (expense.category == "gear") {
          continue;
        }
        sum += expense.amount;
      }
      return sum;
    },
    reccuringExpenseCost() {
      let sum = 0;
      for (const expense of this.reccuringExpenses) {
        if (expense.category == "gear") {
          continue;
        }
        sum += expense.amount;
      }
      return sum;
    },
    crewCost() {
      return utils.finance.revenue(
        this.workdaysfilteredWithoutAdmin,
        this.oneTimePayFilteredWithoutAdmin
      );
    },
    projectIncome() {
      let sum = 0;
      for (const project of this.filteredProjects) {
        sum += project.budget;
      }
      return sum;
    },
    totalIncome() {
      return this.projectIncome;
    },
    oneTimeExpenses() {
      const expenses = this.expenses.filter((item) => item.reccuring == false);
      return expenses.filter((expense) => this.filterDate(expense.date));
    },
    reccuringExpenses() {
      const result = [];
      const expenses = this.expenses.filter((item) => item.reccuring == true);

      // copy it till eternity
      for (const expense of expenses) {
        const expenseObject = { ...expense };
        expenseObject.reference = expenseObject._id;
        result.push(expenseObject);

        const date = moment(expenseObject.date);

        if (this.period == "month") {
          const startDate = moment()
            .year(this.year)
            .month(this.month - 1)
            .date(1);
          const endDate = moment()
            .year(this.year)
            .month(this.month - 1)
            .date(startDate.daysInMonth());

          if (date.isSameOrBefore(endDate, "day")) {
            const endDate = moment(expenseObject.reccuringEndDate);
            const newDate = date.month(this.month - 1).year(this.year);

            if (newDate.isAfter(moment())) {
              break;
            }

            if (endDate._isValid) {
              if (newDate.isSameOrBefore(endDate, "day")) {
                expenseObject.date = newDate;
              }
            } else {
              expenseObject.date = newDate;
            }
          }
        } else {
          let numberOfMonths = 0;

          if (this.period == "year") {
            if (moment(expenseObject.date).year() == this.year) {
              numberOfMonths = 11 - moment(expenseObject.date).month();
            } else if (moment(expenseObject.date).year() <= this.year) {
              numberOfMonths = 12;
            }

            for (let i = 1; i <= numberOfMonths; i++) {
              const date =
                numberOfMonths == 12
                  ? moment(expense.date).year(this.year).month(-1)
                  : moment(expense.date);
              const copy = { ...expenseObject };
              copy.date = date.month(date.month() + i).toISOString();
              copy._id = uuidv4();

              if (moment(date).isAfter(moment())) {
                break;
              }

              const endDate = moment(copy.reccuringEndDate);
              if (endDate._isValid) {
                if (moment(copy.date).isSameOrBefore(endDate, "day")) {
                  result.push(copy);
                } else {
                  break;
                }
              } else {
                result.push(copy);
              }
            }
          }

          if (this.period == "range" || this.period == "all") {
            if (this.period == "range") {
              numberOfMonths = moment(this.endDate).diff(
                moment(expenseObject.date),
                "months"
              );
            }

            if (this.period == "all") {
              numberOfMonths = moment().diff(
                moment(expenseObject.date),
                "months"
              );
            }

            for (let i = 1; i <= numberOfMonths; i++) {
              const date = moment(expense.date);
              const copy = { ...expenseObject };
              copy.date = date.month(date.month() + i).toISOString();
              copy._id = uuidv4();

              if (moment(date).isAfter(moment())) {
                break;
              }

              const endDate = moment(copy.reccuringEndDate);
              if (endDate._isValid) {
                if (moment(copy.date).isSameOrBefore(endDate, "day")) {
                  result.push(copy);
                } else {
                  break;
                }
              } else {
                result.push(copy);
              }
            }
          }
        }
      }

      // filter by date
      return result.filter((expense) => this.filterDate(expense.date));
    },
    workdaysfilteredWithoutAdmin() {
      return this.workdaysfiltered.filter(
        (workday) => workday.user.accessLevel < 10 && workday.state == "signed"
      );
    },
    workdaysfiltered() {
      return this.workdays.filter((workday) => this.filterDate(workday.day));
    },
    oneTimePayFilteredWithoutAdmin() {
      return this.oneTimePayFiltered.filter(
        (oneTimePay) =>
          oneTimePay.user.accessLevel < 10 && oneTimePay.state == "signed"
      );
    },
    oneTimePayFiltered() {
      return this.oneTimePay.filter((oneTimePay) =>
        this.filterDate(oneTimePay.date)
      );
    },
    gearFiltered() {
      return this.gear.filter((gear) => this.filterDate(gear.dateOfPurchase));
    },
    filteredProjects() {
      return this.projects.filter((project) => {
        return (
          project.selectedPricelists.length > 0 &&
          this.filterDate(project.deadline)
        );
      });
    },
  },
  methods: {
    ...mapActions([
      "getExpenses",
      "getWorkdays",
      "getOneTimePay",
      "getPricelistItems",
      "getProjects",
    ]),
    onPeriodChange() {
      this.query = `?period=${this.period}`;

      if (this.period == "year" || this.period == "month") {
        this.query += `&year=${this.year}`;
      }

      if (this.period == "month") {
        this.query += `&month=${this.month}`;
      }

      if (this.period == "range") {
        this.query += `&start=${this.startDate}`;
        this.query += `&end=${this.endDate}`;
      }

      if (
        this.$router.currentRoute.fullPath !=
        this.$router.currentRoute.path + this.query
      ) {
        this.$router.push(this.$router.currentRoute.path + this.query);
      }
    },
    filterDate(date) {
      const dateObject = moment(date);
      if (this.period == "year") {
        return dateObject.year() == this.year;
      } else if (this.period == "month") {
        return (
          dateObject.year() == this.year && dateObject.month() + 1 == this.month
        );
      } else if (this.period == "range") {
        return dateObject.isBetween(this.startDate, this.endDate, "day", "[]");
      } else if (this.period == "all") {
        return true;
      }
      return false;
    },
    refresh() {
      this.getProjects()
        .then((projects) => {
          this.projects = projects.map((project) => {
            const ref = project;

            let sum = 0;
            for (const pricelist of project.selectedPricelists) {
              sum += utils.pricelist.totalPrice(pricelist);
            }

            project.budget = sum;

            return ref;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.getExpenses()
        .then((expenses) => {
          this.expenses = expenses;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getWorkdays()
        .then((workdays) => {
          this.workdays = workdays;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getOneTimePay()
        .then((oneTimePayArray) => {
          this.oneTimePay = oneTimePayArray;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getPricelistItems()
        .then((items) => {
          this.gear = [];

          const itemsWithGear = items.filter((item) => item.gear.length > 0);
          for (const item of itemsWithGear) {
            for (const gear of item.gear) {
              const cache = gear;
              cache.name = item.name;
              cache.item = item;
              this.gear.push(cache);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();

    if (this.$route.query.period) {
      this.period = this.$route.query.period;

      if (
        this.$route.query.period == "year" ||
        this.$route.query.period == "month"
      ) {
        this.year = Number(this.$route.query.year);
      }

      if (this.$route.query.period == "month") {
        this.month = Number(this.$route.query.month);
      }

      if (this.$route.query.period == "range") {
        this.startDate = this.$route.query.start;
        this.endDate = this.$route.query.end;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    from;

    next((vm) => {
      if (to.name) {
        if (vm.$store.state.accessLevel > 9) {
          vm.$router.push("/lifefinance/overview");
        }
      }
    });
  },
};
</script>

<style scoped>
.h-stack div {
  align-self: center;
}

.h-stack label {
  align-self: center;
}

.h-stack select {
  align-self: center;
  width: 160px;
}
</style>
